export default [
  {
    header: 'Apps',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Solutions',
        icon: 'ServerIcon',
        route: 'apps-solutions',
        resource: 'ACL',
        action: 'read',
      },
      {
        title: 'Book a Time',
        route: 'apps-calendar',
        icon: 'CalendarIcon',
        resource: 'ACL',
        action: 'read'
      },
      {
        title: 'Use cases',
        route: 'apps-usecases',
        icon: 'CloudLightningIcon',
        resource: 'ACL',
        action: 'read',
      },
      {
        title: 'Customers',
        route: 'app-customers',
        icon: 'UsersIcon',
        resource: 'Admin',
        action: 'read',
      },
      {
        title: 'AWS Marketplace',
        route: 'apps-market-place',
        icon: 'LayersIcon',
        resource: 'ACL',
        action: 'read',
      },
      {
        title: 'FAQ',
        route: 'apps-faq',
        icon: 'FileTextIcon',
        resource: 'Shared',
        action: 'read',
      },
      {
        title: 'My Products',
        route: 'apps-products',
        icon: 'CheckSquareIcon',
        resource: 'Shared',
        action: 'read',
      },
      {
        title: 'Account Settings',
        icon: 'UserIcon',
        resource: 'Shared',
        route: 'apps-view-profile',
        action: 'read',
      }
      // {
      //   title: 'License',
      //   route: 'apps-license',
      //   icon: 'FileIcon',
      //   resource: 'Shared',
      //   action: 'read',
      // },
    ],
  },
]
